import { Col, Row } from 'react-bootstrap';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404 Not Found" />
      <Row className="text-center container mx-auto py-5">
        <Col>
          <Row>
            <Col>
              <h1>Page not found</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5>Oops!😧 We couldn’t find what you were looking for.</h5>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  );
}

export default NotFoundPage;
